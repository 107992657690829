import React from "react";



const SearchBox = ({ styleName, placeholder, onChange, value }) => {



  

  return (

    <form  className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <input className="ant-input" type="search" placeholder={placeholder} onChange={onChange}
          value={value} />
        <button type="submit"  className="gx-search-icon gx-pointer"><i className="icon icon-search-users icon-search" /></button>
      </div>
    </form>

  )
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
