import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
     <Route path={"/home"} component={asyncComponent(() => import('./pages/Wellcome'))}/> 
      <Route path={`${match.url}manage-users`} component={asyncComponent(() => import('./pages/Manage-users'))}/>
      <Route path={`${match.url}search/user`} component={asyncComponent(() => import('./pages/Manage-users/SearchUser'))}/>
      {/* <Route path={`${match.url}search-result`} component={asyncComponent(() => import('./pages/Manage-users/ResultSearch'))}/> */}
      <Route path={`${match.url}user/details`} component={asyncComponent(() => import('./pages/Manage-users/Card'))}/>
      <Route path={`${match.url}manage-users-videos`} component={asyncComponent(() => import('./pages/Manage-users/page-videos'))}/>
      <Route path={`${match.url}manage-users-comments`} component={asyncComponent(() => import('./pages/Manage-users/page-comments'))}/>
      <Route path={`${match.url}edit-ads`} component={asyncComponent(() => import('./pages/EditAds'))}/>
      <Route path={`${match.url}manage-main-feed`} component={asyncComponent(() => import('./pages/Manage-mainFeed'))}/>
      <Route path={`${match.url}manage-categories`} component={asyncComponent(() => import('./pages/Manage-categories'))}/>
      <Route path={`${match.url}manage-explore`} component={asyncComponent(() => import('./pages/manage-explore'))}/>
      <Route path={`${match.url}leaderbor-explore`} component={asyncComponent(() => import('./pages/Leaderboards-explore/form-leader-explore'))}/> 
      <Route path={`${match.url}leaderbor-category`} component={asyncComponent(() => import('./pages/Leaderboards-category/form-leader-categories'))}/>
      <Route path={`${match.url}main-feed`} component={asyncComponent(() => import('./pages/MainFeed/form-main-feed'))}/>
      <Route path={`${match.url}match-scoring`} component={asyncComponent(() => import('./pages/Match-Scoring'))}/>
      <Route path={`${match.url}add-sports`} component={asyncComponent(() => import('./pages/ManageSports/AddSport'))}/>
      <Route path={`${match.url}add-sport-disciplines`} component={asyncComponent(() => import('./pages/ManageSports/DisciplineSports'))}/>
      <Route path={`${match.url}edit/sports`} component={asyncComponent(() => import('./pages/ManageSports/EditSport'))}/>
      <Route path={`${match.url}content-manage-video`} component={asyncComponent(() => import('./pages/ContentManage'))}/>
      <Route path={`${match.url}scoring-manage`} component={asyncComponent(() => import('./pages/ScoringManage'))}/>
      <Route path={`${match.url}live-matchplay`} component={asyncComponent(() => import('./pages/LiveMatchplay/LiveMatchplay'))}/>
      <Route path={`${match.url}create-event`} component={asyncComponent(() => import('./pages/Events/Events'))}/>
      <Route path={`${match.url}list-matchplay`} component={asyncComponent(() => import('./pages/LiveMatchplay/ListMatchplay'))}/>
      <Route path={`${match.url}list-events`} component={asyncComponent(() => import('./pages/Events/ListEvents'))}/>
      <Route path={`${match.url}event-add`} component={asyncComponent(() => import('./pages/Events/AddEvent'))}/>
      <Route path={`${match.url}event/:id`} component={asyncComponent(() => import('./pages/Events/SingleEvent'))}/>
    </Switch>
  </div>
)

export default App;
