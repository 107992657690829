import { createContext, useCallback, useMemo, useState } from "react";

export const AuthContext = createContext();

export default function AuthContexProvider({ children }) {
  const [isAuthenticate, setisAuthenticate] = useState(
    window.localStorage.getItem("user")
  );

  const login = useCallback(() => {
    window.localStorage.setItem("user", true);
    setisAuthenticate(true);
  }, []);

  const logout = useCallback(() => {
    window.localStorage.clear()
    window.localStorage.removeItem("user")
    setisAuthenticate(false);
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuthenticate,
    }),
    [isAuthenticate, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
