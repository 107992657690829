import React from "react";
// import { Link } from "react-router-dom";
import "./css/AppsNavigation.css";



const AppsNavigation = () =>
  <ul key="home" className="gx-app-nav link-home">
    {/* <Link className="link-home" to="/home" >Home</Link> */}
  </ul>;
  

export default AppsNavigation;
