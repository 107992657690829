import React from "react";
import {Avatar, Popover} from "antd";
import useAuthContext from "../../hooks/useAuthContext";
import {FiLogOut} from "react-icons/fi";
import "./css/userProfile.css"

const UserProfile = () => {
  const { logout } = useAuthContext();

  let iconStyles = {marginLeft: "10px", color: "#038fde"};

  const nameUser = window.localStorage.getItem("name-User")
  
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li className="button-logout" onClick={logout}>Logout< FiLogOut style={iconStyles}  /></li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 container-user-profile ">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://via.placeholder.com/150"} className="gx-size-35 gx-pointer gx-mr-3" alt=""/>
        <span  className="gx-avatar-name ">{nameUser ? nameUser : "Admin@getSportMatch.com"}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default UserProfile;
