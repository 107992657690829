import React from 'react';
import {Redirect, Route } from 'react-router-dom';
import useAuthContext from '../hooks/useAuthContext';

const PublicRoute = ({component : Component, ...rest}) => {
    const {isAuthenticate} = useAuthContext();

    if (isAuthenticate) {
        return <Redirect to="/home" />
    }
    return <Route {...rest} component={Component} />
}

export default PublicRoute;