import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import "../css/home.css"
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const SubMenu = Menu.SubMenu;


  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {/* <MenuItemGroup key="main" className="gx-menu-group" > */}
            <SubMenu key="dashboard" popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={<span><i className="icon icon-dasbhoard" />
                <span>Sports Management</span></span>}>

              <Menu.Item key="add-sports">
                <Link to="/add-sports">
                  <i className="icon icon-crypto" />
                  <span>Add Sports</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="add-sport-disciplines">
                <Link to="/add-sport-disciplines">
                  <i className="icon icon-crm" />
                  <span>Add Sports Disciplines</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="edit/sports">
                <Link to="/edit/sports">
                  <i className="icon icon-listing-dbrd" />
                  <span>Edit Sports</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            {/* </MenuItemGroup> */}


            <SubMenu key="dashboard2"
              title={<span><i className="icon icon-auth-screen" />
                <span>Manage Ads</span></span>}>

              <Menu.Item key="manage-explore">
                <Link to="/manage-explore">
                  <i className="icon icon-crypto" />
                  <span> Manage Leaderboard Explore</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="manage-categories">
                <Link to="/manage-categories">
                  <i className="icon icon-crm" />
                  <span>Manage Leaderboard Categories</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="manage-main-feed">
                <Link to="/manage-main-feed">
                  <i className="icon icon-listing-dbrd" />
                  <span>Manage Main Feed</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="dashboard4"
              title={<span> <i className="icon icon-feedback" />
                <span>Add an ad</span></span>}>

              <Menu.Item key="leaderbor-explore">
                <Link to="/leaderbor-explore">
                  <i className="icon icon-crypto" />
                  <span> Leaderboard Explore</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="leaderbor-category">
                <Link to="/leaderbor-category">
                  <i className="icon icon-crm" />
                  <span>Leaderboard Categories</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="main-feed">
                <Link to="/main-feed">
                  <i className="icon icon-listing-dbrd" />
                  <span>Main Feed</span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="match-scoring">
              <i className="icon icon-lock-screen" />
              <Link to="/match-scoring" className="ant-dropdown-link">
                Video Management
              </Link>
            </Menu.Item>

             <Menu.Item key="search/user">
              <i className="icon icon-widgets" />
              <Link to="/search/user" className="ant-dropdown-link">
                User Management
              </Link>
            </Menu.Item>
            <SubMenu key="livematchplay"
              title={<span> <i className="icon icon-user" />
                <span>Live Matchplay</span></span>}>

              <Menu.Item key="creatematchplay">
                <Link to="/live-matchplay">
                  <i className="icon icon-add" />
                  <span> Create Matchplay</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="listmatchplay">
                <Link to="/list-matchplay">
                  <i className="icon icon-listing-dbrd" />
                  <span> List Matchplay</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="events"
              title={<span> <i className="icon icon-feedback" />
                <span>Events</span></span>}>
              <Menu.Item key="listevents">
                <Link to="/list-events">
                  <i className="icon icon-listing-dbrd" />
                  <span> Event List</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="event">
                <Link to="/event-add">
                  <i className="icon icon-add" />
                  <span>Create event</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            {/* <Menu.Item key="/live-matchplay">
              <i className="icon icon-user" />
              <Link to="/live-matchplay" className="ant-dropdown-link">
                Live Matchplay
              </Link>
            </Menu.Item> */}

            {/*     <Menu.Item key="scoring-manage">
              <i className="icon icon-chart-bar" />
              <Link to="/scoring-manage" className="ant-dropdown-link">
                Scoring Management
              </Link>
            </Menu.Item> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

