import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import logoCompete from "../img/logocompete.png"
import Notify from "../Notifications/login/index"
import "./css/signin.css"
import { MdEmail } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import CircularProgress from "../components/CircularProgress";
import useAuthContext from "../hooks/useAuthContext"
import Axios from "../services/login"


const SignIn = () => {
  const [loading, setLoading] = useState(null)
  //container of all form states
  const [form, setForm] = useState({
    email: "",
    password: ""
  })

  //function redirect to home
   const { login } = useAuthContext();

  //function send data api
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const user = await Axios.Login(form)
      if (user) {
        localStorage.setItem('token-admin', user.tokens.access.token)
        localStorage.setItem("name-User", user.user.email)
        setLoading(true)
        setTimeout(() => {
          login();
        }, 2000);
      }
      } catch (e) {
        Notify.ErrorLogin()
        console.log(e)
      }
    }

  if (loading === true) {
      return (
        <CircularProgress />
      )
    }
    else {
      return (
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                </div>
                <div className="gx-app-logo  logo-compete">
                  <img alt="logo" src={logoCompete} />
                </div>
              </div>
              <div className="gx-app-login-content content-login">
                <Form
                  className="gx-signin-form gx-form-row0">
                  <label className="email-login" ><i className="item-emal-login"><MdEmail /></i> Email</label>
                  <Form.Item
                    required name="email">
                    <Input
                      type="email"
                      placeholder="Email"
                      value={form.email}
                      onChange={({ target }) => setForm({ ...form, email: target.value })}
                    />
                  </Form.Item>
                  <label className="password-login" ><i className="item-key-login"> <FaKey /> </i> Password</label>
                  <Form.Item
                    required name="password">
                    <Input
                      type="password"
                      placeholder="Password"
                      value={form.password}
                      onChange={({ target }) => setForm({ ...form, password: target.value })}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" className="gx-mb-0" onClick={handleLogin}>Sign in
                    </Button>
                  </Form.Item>
                  <h4 className="compete">COMPETE</h4>
                  <span className="span-only-admin">Only for administratorss</span>
                </Form>
              </div>
            </div>
          </div>
        </div>
      );
    };
  }

  export default SignIn;
