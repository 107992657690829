import React from "react";
import { Layout } from "antd";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
// import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
// import AppNotification from "../../components/AppNotification";
// import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import "./css/index-topbar.css"


import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

const { Header } = Layout;

const Topbar = () => {

  const { navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  // const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();


  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search Users"
        onChange={updateSearchChatUser}
        value={searchText} /> */}
      <ul className="gx-header-notifications gx-ml-auto">
        {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
              placeholder="Search Users"
              onChange={updateSearchChatUser}
              value={searchText} />
          } trigger="submit">
            <Link  className="gx-pointer gx-d-block"><i className="icon icon-search-new" /></Link>
          </Popover>
        </li> */}
        {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
              <Link to="/home" className="mr-2" >Home</Link>
          </Auxiliary>
        } */}
       
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo /></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
