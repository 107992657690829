import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRouter";
import PublicRoute from "../../routes/PublicRouter";
import { IntlProvider } from "react-intl";
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import AuthContexProvider from "../../global/AuthContext";




const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const match = useRouteMatch();


  const currentAppLocale = AppLocale[locale.locale];

  return (
    <AuthContexProvider>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Switch>
          <PublicRoute exact path='/' component={SignIn} />
          <PrivateRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </AuthContexProvider>
  )
};

export default memo(App);
