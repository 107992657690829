import axios from "axios";
import { url } from "./config";

const login = `https://${url}/v1/auth/loginAdmin`;

//for login
const Login = async (form) => {
 const {data} = await axios({
   method: 'post',
   url: login,
   data: {
     email: form.email, 
     password: form.password
   }
 });
  return data
}; 

const Axios = {  Login };

export default Axios