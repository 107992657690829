import Swal from "sweetalert2"

function ErrorLogin () {
Swal.fire({
    icon: 'error',
    title: 'Wrong credentials',
    showConfirmButton: false,
    timer: 1000
  })
}

const Notify = {
    ErrorLogin
}

export default Notify