import React from 'react';
import {Redirect, Route } from 'react-router-dom';
import useAuthContext from '../hooks/useAuthContext';

const PrivateRoute = ({component : Component, ...rest}) => {
    const {isAuthenticate} = useAuthContext();

    if (!isAuthenticate) {
        return <Redirect to="/" />
    }
    return <Route {...rest} component={Component} />
}

export default PrivateRoute;